import { navigate } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"
import AuthCard, { AuthCardType } from "../../components/dashboard/AuthCard"
import ShellLogo from "../../images/shell_logo.png"
import { checkUserExists } from "../../store/actions/authActions"
import { updatePromotionCode } from "../../store/actions/promotionActions"
import { authStateInterface } from "../../store/reducers/authReducer"
import { promotionStateInterface } from "../../store/reducers/promotionReducer"
import { utilsStateInterface } from "../../store/reducers/utilsReducer"

interface Props {
  authStore: authStateInterface
  utilsStore: utilsStateInterface
  promotionStore: promotionStateInterface
  updatePromotionCode: (promoCode: string) => void
}
interface State {
  firstSignUp: boolean
  signUpType: AuthCardType
}

class SignUp extends Component<Props> {
  state: State = {
    firstSignUp: false,
    signUpType: AuthCardType.SIGNUPOPTIONS,
  }

  componentDidMount() {
    this.handleCheckAuthNavigator()
    const searchParams = new URLSearchParams(window.location.search)
    const promoCode = searchParams.get("promo")
    if (promoCode) {
      this.props.updatePromotionCode(promoCode)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.authStore.userLoading !== this.props.authStore.userLoading ||
      prevProps.authStore.userAuth !== this.props.authStore.userAuth ||
      prevProps.authStore.user !== this.props.authStore.user
    ) {
      this.handleCheckAuthNavigator()
    }
  }

  handleCheckAuthNavigator = async () => {
    if (this.props.authStore.userAuth) {
      const userExists = await checkUserExists(this.props.utilsStore.firebase)
      if (!userExists) {
        this.handleChangePage(AuthCardType.SIGNUP)
      } else {
        if (!this.state.firstSignUp) {
          navigate("/dashboard")
        }
      }
    }
  }

  handleChangePage = async (type: AuthCardType) => {
    this.setState({
      signUpType: type,
    })
  }

  handleFirstSignUp = () => {
    this.setState({
      firstSignUp: true,
    })
  }

  renderPromoLanding = () => {
    if (this.props.promotionStore.promotionCode === "SHELL") {
      return (
        <div>
          <img className="h-16 mt-6 mb-2" src={ShellLogo} alt="Shell Logo" />
          <h3 className="text-gray-500 -mb-4">
            A collaboration that helps you recycle your used cooking oil (UCO)
            into biodiesel for the benefit of a greener future.
            {"\n"}
            <h3 className="font-bold mt-2 sm:mt-1">
              Sign up for an additional 10% in cashback when you recycle your
              UCO
            </h3>
          </h3>
        </div>
      )
    }
  }

  render() {
    if (this.props.authStore.userLoading) {
      return null
    }

    return (
      <div className="bg-white">
        <SEO
          title="Arus Oil - Sign Up"
          description="Sign up an account in Arus Oil now. This allows you to view daily purchase rates of used cooking oil,contact logistic for collection and view payment made to you."
        />
        <Header page="Sign Up" />
        <main className="overflow-hidden pb-16">
          <div className="bg-warm-gray-50">
            <div className="py-16">
              <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
                <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl">
                  Sign Up
                </h1>
                {this.renderPromoLanding()}
              </div>
            </div>
          </div>

          <section
            className="relative bg-white"
            aria-labelledby="contactHeading"
          >
            <div
              className="absolute w-full h-1/2 bg-warm-gray-50"
              aria-hidden="true"
            ></div>
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <svg
                className="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-warm-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
            </div>
            <div className="pb-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <AuthCard
                type={this.state.signUpType}
                handleFirstSignUp={this.handleFirstSignUp}
                handleChangePage={this.handleChangePage}
              />
            </div>
          </section>
        </main>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    promotionStore: state.promotionStore,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updatePromotionCode: (promoCode: string) =>
      dispatch(updatePromotionCode(promoCode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
